
import { computed, defineComponent, ref, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import router from "@/router";
import moment from "moment";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "dashboard",
  components: {},
  data() {
    return {
      current_page: 0,
      loading: false,
      filter: {
        dificulty: "",
        status: "",
        search: "",
        show_filter: false
       
      }
    };
  },
  setup() {
    const store = useStore();
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));



    onMounted(() => {
      setCurrentPageTitle("Machines List");
    });

    store.dispatch(Actions.GET_MACHINES_FILTERED, { type: "default"});

    const machines = computed(() => {
      return store.getters.getAllMachines;
    });


    const currentMetadata = computed(() => {
      return store.getters.getMachineMetadata;
    });

    const show_filter = ref(false);

    return {
      moment,
      router,
      machines,
      currentMetadata,
      store,
      show_filter,
      currentLanguage
    };
  },
  methods: {
    async goTo(url) {
      if(url){
        const page = url.split("page=")[1];
        this.loading = true;
        await this.store.dispatch(Actions.GET_MACHINES_FILTERED, { type: "default", dificulty: this.filter.dificulty, status: this.filter.status, search: this.filter.search, page: page });
        this.loading = false;
      }
     
    },
   async doSearch() {

      if(this.loading){
        return;
      }
     
      this.loading = true;
     
      await this.store.dispatch(Actions.GET_MACHINES_FILTERED, { type: "default", dificulty: this.filter.dificulty, status: this.filter.status, search: this.filter.search});
      this.loading = false;
      

    }
  }
});
